import { grayColor } from "assets/jss/material-kit-pro-react.js"

const introductionStyle = {
  introSection: {
    paddingBottom: "0px !important",
  },
  introTxt: {
    color: grayColor[0],
    textAlign: "justify",
    textJustify: "inter-word",
  },
}

export default introductionStyle
