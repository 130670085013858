import { title } from "assets/jss/material-kit-pro-react.js"
import cardStyle from "assets/jss/material-kit-pro-react/components/cardStyle"

const servicesStyle = {
  serviceTxtContainer: {
    padding: "0px 30px",
  },
  cardTitle: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "2rem",
    fontWeight: 500,
  },
  cardMedia: {
    ...cardStyle.card,
    maxHeight: "300px",
  },
}

export default servicesStyle
