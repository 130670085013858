import { main, mainRaised } from "assets/jss/material-kit-pro-react.js"

const landingPageStyle = {
  main: {
    ...main,
    ...mainRaised,
  },
}

export default landingPageStyle
