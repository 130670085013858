import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import Section from "components/appComponents/Section/Section"
// Style
import styles from "assets/jss/app/views/introductionStyle"

const IntroductionSection = withStyles(styles)((props) => {
  const { classes } = props
  return (
    <Section id="introduction" className={classes.introSection}>
      <h5 className={classes.introTxt}>
        Cities are multilayered structures where ecology, technology, and humans
        develop a particular protocol to interact amongst themselves. The city
        itself is already a technology, one of the oldest and most resilient
        ones which has become a complex platform for its users. Our mission is
        to enhance and improve user's experiences in the city by uncovering and
        understanding the different layers stacking over the built environment
        and its inhabitants.
      </h5>
    </Section>
  )
})

//export default withStyles(productStyle)(ServiceSection)
export default IntroductionSection
