import React from "react"
import { getSrc } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, featuredImage }) => {
  const location = useLocation()

  const { site, defaultImage } = useStaticQuery(query)
  const {
    siteName,
    titleTemplate,
    defaultTitle,
    defaultDescription,
    siteUrl,
    lang,
  } = site.siteMetadata

  const image = featuredImage || defaultImage
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    imageUrl: `${siteUrl}${getSrc(image)}`,
    imageWidth: image.childImageSharp.gatsbyImageData.width,
    imageHeight: image.childImageSharp.gatsbyImageData.height,
    url: `${siteUrl}${location.pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        lang: lang,
      }}
    >
      <meta charSet="utf-8" />
      <meta name="robots" content="all" />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.imageUrl} />
      <meta name="og:image:width" content={seo.imageWidth} />
      <meta name="og:image:height" content={seo.imageHeight} />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.imageUrl && <meta property="og:image" content={seo.imageUrl} />}

      <meta name="twitter:card" content="summary_large_image" />
      {/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )} */}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.imageUrl && <meta name="twitter:image" content={seo.imageUrl} />}
    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  featuredImage: PropTypes.object,
}

Seo.defaultProps = {
  title: null,
  description: null,
  featuredImage: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteName
        titleTemplate
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        lang
      }
    }
    defaultImage: file(
      absolutePath: { glob: "**/src/assets/img/welcome-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`
