import React from "react"
// Gatsby components
import { useStaticQuery, graphql } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import Footer from "components/appComponents/Footer/Footer.js"
// Sections components
import Seo from "components/appComponents/seo/seo"
import HeaderSection from "./Sections/HeaderSection.js"
import IntroductionSection from "./Sections/IntroductionSection"
import ServicesSection from "./Sections/ServicesSection.js"
import TeamSection from "./Sections/TeamSection.js"
import ProjectsSection from "./Sections/ProjectsSection.js"
import ContactSection from "./Sections/ContactSection.js"
// Style images
import style from "assets/jss/app/views/homePageStyle"

const query = graphql`
  query Home {
    site {
      siteMetadata {
        title
        description
      }
    }
    imageSharp: file(
      absolutePath: { glob: "**/src/assets/img/welcome-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`

export default function UrbanCoPage({ ...rest }) {
  const useStyles = makeStyles(style)
  const classes = useStyles()

  const { site, imageSharp } = useStaticQuery(query)
  const { title, description } = site.siteMetadata
  const image = imageSharp

  const year = new Date().getFullYear()

  return (
    <div>
      <Seo
        title={title}
        description={description}
        image={image.childImageSharp?.gatsbyImageData}
      />
      <HeaderSection title={title} description={description} image={image} />
      <div className={classes.main}>
        <IntroductionSection />
        <ServicesSection />
        <TeamSection />
        <ProjectsSection />
        <ContactSection />
      </div>
      <Footer content={<p>Copyright © {year} UXCITY</p>} />
    </div>
  )
}
