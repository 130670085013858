import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import { StaticImage } from "gatsby-plugin-image"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Section from "components/appComponents/Section/Section"
import Badge from "components/Badge/Badge"
// Style
import servicesStyle from "assets/jss/app/views/servicesStyle"

const ServicesSection = withStyles(servicesStyle)((props) => {
  const { classes } = props
  return (
    <Section title="WHAT WE DO?" id="services">
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Badge color="primary">Scope_01</Badge>
          <div className={classes.serviceTxtContainer}>
            <h2 className={classes.cardTitle}>
              Identify challenge <br />
              patterns
            </h2>
            <h5 className={classes.description}>
              Primary and secondary Research | Current and Future Policies,
              Stakeholders and User Research | Benchmark Definition | Data
              Collection
            </h5>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <StaticImage
            src="../../../assets/img/uxcity/services/step_01.png"
            alt="UXCity services: step_01 - Identify challenge patterns"
            objectFit="cover"
            placeholder="blurred"
            className={classes.cardMedia}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Badge color="primary">Scope_02</Badge>
          <div className={classes.serviceTxtContainer}>
            <h2 className={classes.cardTitle}>
              Understand the environment from a global perspective
            </h2>
            <h5 className={classes.description}>
              Data analysis | Data Mapping | Definition of Spatial Indicators |
              Definition of KPI's
            </h5>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <StaticImage
            src="../../../assets/img/uxcity/services/step_02.png"
            alt="UXCity services: step_02 - Understand the context from a global perspective"
            objectFit="cover"
            placeholder="blurred"
            className={classes.cardMedia}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Badge color="primary">Scope_03</Badge>
          <div className={classes.serviceTxtContainer}>
            <h2 className={classes.cardTitle}>
              Digital models to test success
            </h2>
            <h5 className={classes.description}>
              Development of Digital models | Simulations and Statistical
              operations | Summary and Conclusions
            </h5>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <StaticImage
            src="../../../assets/img/uxcity/services/step_03.png"
            alt="UXCity services: step_03 - Digital models to test success"
            objectFit="cover"
            placeholder="blurred"
            className={classes.cardMedia}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Badge color="primary">Scope_04</Badge>
          <div className={classes.serviceTxtContainer}>
            <h3 className={classes.cardTitle}>
              Strategy and product <br />
              design
            </h3>
            <h5 className={classes.description}>
              Strategic solutions, actions and road map | Data Visualization |
              Web Applications Development | storytelling.
            </h5>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <StaticImage
            src="../../../assets/img/uxcity/services/step_04.png"
            alt="UXCity services: step_04 - Strategy and product design"
            objectFit="cover"
            placeholder="blurred"
            className={classes.cardMedia}
          />
        </GridItem>
      </GridContainer>
    </Section>
  )
})

//export default withStyles(productStyle)(ServiceSection)
export default ServicesSection
