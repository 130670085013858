import React from "react"
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import Section from "components/appComponents/Section/Section"
import ContactDialog from "components/appComponents/ContactDialog/ContactDialog"
import Button from "components/CustomButtons/Button.js"
// style
import contactStyle from "assets/jss/app/views/contactStyle"

const ContactSection = withStyles(contactStyle)((props) => {
  // const { classes } = props

  const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
  const openDialog = () => setDialogIsOpen(true)
  const closeDialog = () => setDialogIsOpen(false)

  return (
    <Section title="Contact us" id="contact" color="primary" border="bottom">
      <h5>For all general and business inquiries, please use this form:</h5>
      <ContactDialog open={dialogIsOpen} onClose={closeDialog} />
      <Button variant="contained" color="primary" onClick={openDialog}>
        Contact form
      </Button>
    </Section>
  )
})

export default ContactSection
